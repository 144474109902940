<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{$store.state.sysenv.codePropagated.name}}
                <small>{{ $store.state.sysenv.codePropagated.parentName }}</small>
              </h5>
            </div>
            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="itemSn"
            hide-actions
            draggable
            :headers="headers"
            :items="navItem"
            @row-move-to="onRowMoved"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus($event, props)">
                <td style="text-align: center;" v-if="codePropagated.code !== 'ALL'" class="handle">
                  <button class="jcon_order"></button>
                </td>
                <td v-if="codePropagated.code === 'ALL'">{{ props.item.groupName }}</td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.itemCode }}</td>
                <td>
                  <div style="display:flex;">
                    {{ props.item.itemName }}
                    <img
                      v-if="!!props.item.navIconPath"
                      style="margin-left: 3px;"
                      height="14px"
                      :src="props.item.navIconPath"
                    />
                  </div>
                </td>
                <td>{{ props.item.cnName }}</td>
                <td>{{ props.item.pdfTime }}</td>                  
                <td>{{ props.item.itemStatusName }}</td>
                <td style="text-align: center;">{{ props.item.toolBar }}</td>
                <td style="text-align: center;">{{ props.item.subItem }}</td>
                <td style="text-align: center;">{{ props.item.nonFilter }}</td>
                <td>{{ props.item.itemPermitName }}</td>
                <td v-html="formatWButton(props.item.reportName)"></td>
                <!-- <td>{{ props.item.url }}</td> -->
                <td>{{ props.item.customCompoment }}</td>
                <td>{{ props.item.direction }}</td>
                <td v-if="UpdateVisible" @click="dbTranster(props)">Update</td>
              </tr>
            </template>
            
            <template v-slot:no-results>
              <v-alert
                :value="true"
                color="error"
                icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert>
            </template>
          </j-data-grid>
        </div>
      </div>
  
      <j-form-modal
        title="Menu Item"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12 sm6 class="wrap__select">
              <v-select
                v-model="selectedItem.phaseCode"
                dense
                required
                class="field"
                label="Phase Title"
                item-text="phaseName"
                item-value="phaseCode"
                placeholder="Select a Phase"
                :menu-props="{contentClass:'single_select'}"
                :rules="codeRules"
                :items="phaseOptions"
                @input="phaseChanged"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6 class="wrap__select">
              <v-select
                v-model="selectedItem.groupCode"
                dense
                required
                label="Group Title"
                class="field"
                item-text="groupName"
                item-value="groupCode"
                placeholder="Select a Group"
                :menu-props="{contentClass:'single_select'}"
                :rules="codeRules"
                :items="groupOptions"
              ></v-select>
            </v-flex>

            <v-flex xs12 class="wrap__picker">
              <v-text-field
                v-model="selectedItem.itemName"
                required
                label="En Title"
                placeholder="Input/Edit En Title"
                :rules="defaultRules"
              ></v-text-field>
              <v-menu
                v-model="iconPickerOpen"
                offset-y
                content-class="j_picker icon_picker"
                :close-on-content-click="false"
                :min-width="300"
                :nudge-left="18"
              >
                <template v-slot:activator="{ on }" class="ddd">
                  <div class="picker">
                    <span class="label">Icon</span>
                    <v-btn
                      v-on="on"
                      @click="popIconPicker(!iconPickerOpen)"
                      :style="'background-image: url('+icon.filePath+'); background-size: 100%;'"
                    ></v-btn>
                  </div>
                </template>
                <v-card>
                  <div class="j_picker__header">
                    <span>
                      SVG Icon Picker
                      <small>SVG</small>
                    </span>
                  </div>
                  <div class="j_picker__contents">
                    <div class="current_icon">
                      <div :key="iconPickerOpen">
                        <button @click="removeIcon">
                          <v-icon>mdi-close</v-icon>
                        </button>
                        <img v-show="!!icon.filePath&& icon.filePath != ''" :src="icon.filePath" />
                      </div>
                      <span>{{icon.fileName}}</span>
                    </div>
                    <div class="select_icon">
                      <button
                        v-for="icon in iconList"
                        :value="icon.name"
                        :key="icon.iconCode"
                        @click="iconSelect(icon)"
                      >
                        <img :src="icon.filePath" />
                      </button>
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm6>
              <v-flex xs6 class="wrap__option_group">
                <span class="label">Item Status</span>
                <div class="option_group">
                  <label :for="option.text" v-for="option in statusOptions" :key="option.index">
                    <input
                      v-model="selectedItem.itemStatus"
                      type="radio"
                      name="status"
                      :id="option.text"
                      :value="option.value"
                    />
                    {{ option.text }}
                  </label>
                </div>
              </v-flex>
              <v-layout row style="margin: 0 -1.5rem;">
                <v-flex xs12 sm6>
                  <j-radio-group
                    v-model="selectedItem.toolBar"
                    label="Tool Bar"
                    name="toolBar"
                    :checked="'N'"
                    :options="[
                      { text: 'Yes', value: 'Y' },
                      { text: 'No', value: 'N' },
                    ]"
                  />
                </v-flex>

                <v-flex xs12 sm6>
                  <j-radio-group
                    v-model="selectedItem.subItem"
                    label="Sub-Item"
                    name="subItem"
                    :checked="'N'"
                    :options="[
                      { text: 'Yes', value: 'Y'},
                      { text: 'No', value: 'N'},
                    ]"
                  />
                </v-flex>

                <v-flex xs12 sm6>
                  <j-radio-group
                    v-model="selectedItem.nonFilter"
                    label="Non-Historical-Filter"
                    name="nonFilter"
                    :checked="'N'"
                    :options="[
                      { text: 'Yes', value: 'Y'},
                      { text: 'No', value: 'N'},
                    ]"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            
            <v-flex xs12 sm6 class="wrap__multiselect">
              <v-select
                v-model="menuPermitSelected"
                :items="menuPermitOptions"
                item-text="name"
                return-object
                :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
                label="Item Permission"
                multiple
                placeholder="Select permissions to apply"
              ></v-select>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.cnName"
                required
                label="CN Name"
                placeholder="Input/Edit Cn Name"
              ></v-text-field>
            </v-flex> 
            
           <v-flex xs12>
              <v-text-field
                v-model="selectedItem.pdfTime"
                required
                label="Pdf Export Time"
                placeholder="Input/Edit Pdf Export Time"
              ></v-text-field>
            </v-flex> 

            <v-flex xs12 class="wrap__button_group" v-show="formode == 'MOD' && wButton.length > 0">
              <span>Writable Button</span>
              <div class="button_group">
                <v-btn
                  class="writable j_button"
                  v-for="item in wButton"
                  :key="item.index"
                  @click="openWButton(item)"
                >{{item.reportName}}</v-btn>
              </div>
            </v-flex>
            <v-flex xs12 sm6 class="wrap__select">
              <v-select
                v-model="selectedItem.direction"
                dense
                required
                label="Direction"
                class="field"
                item-text="text"
                item-value="value"
                placeholder="Select a Target"
                :menu-props="{contentClass:'single_select'}"
                :rules="codeRules"
                :items="componentDirectionOptions"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.customCompoment"
                required
                label="Custom Compoment"
                placeholder="Input/Edit Compoment Name"
              ></v-text-field>
            </v-flex>  
            <!-- <v-flex xs12 sm6 class="wrap__select">
              <v-select
                v-model="selectedItem.direction"
                dense
                required
                label="Direction"
                class="field"
                item-text="text"
                item-value="value"
                placeholder="Select a Target"
                :menu-props="{contentClass:'single_select'}"
                :rules="codeRules"
                :items="componentDirectionOptions"
              ></v-select>
            </v-flex> -->
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <j-writable-box
      formode="MOD"
      :open.sync="writableBoxOpen"
      :receivedItem="writableBoxReqItem"
      :comparedItem="writableBoxComparedItem"
      @changed="writableBoxChanged"
      @yes="yes()"
    ></j-writable-box>

    <j-snackbar
      :snackbar="snackbar"
      :snackbarColor="'success'"
      :closeBtnColor="'buttonColor'"
      :positionX="'top'"
      :positionY="'right'"
      :top="'115px'"
      :timeout="2000"
      :mode="'mode'"
      :text="'Priority changed'"
    ></j-snackbar>
  </div>
</template>

<script>
import __C from "@/primitives/_constant_"
import { SystemService, SystemCodeService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import JWritableBox from "@/components/popup/JWritableBox"
import JSnackbar from "@/components/JSnackbar"
import StaticOptions from '@/mixins/staticOptions'
import Loading from '@/mixins/loading.mixin'

export default {
  name: "sysenv_code",
  mixins: [
    Loading,
    StaticOptions
  ],
  components: {
    JFormModal,
    JSnackbar,
    JWritableBox,
  },
  data: () => ({
    UpdateVisible: true,
    systemService: null,
    systemCodeService: null,
    snackbar: false,
    modOpen: false,
    iconPopoverShow: false,
    iconList: [],
    icon: {},
    phaseOptions: [],
    groupOptions: [],
    prevCodes: {
      phaseCode: "",
      groupCode: "",
    },
    menuPermitOptions: [],
    menuPermitSelected: [],
    statusOptions: [],
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "drag", text: "", align: "right", value: "", width: 30 },
      { type: "text", text: "Group Title", align: "left", value: "groupName", width: 170 },
      { type: "conf", text: "No", align: "center", value: "no", width: 50 },
      { type: "text", text: "Code", align: "left", value: "itemCode", width: 100 },
      { type: "text", text: "Title", align: "left", value: "itemName", width: 200 },
      { type: "text", text: "CnName", align: "left", value: "cnName", width: 150 },
      { type: "text", text: "PdfTime", align: "left", value: "pdfTime", width: 80 },            
      { type: "text", text: "Status", align: "left", value: "statusName", width: 80 },
      { type: "text", text: "Tool", align: "center", value: "toolBar", width: 40 },
      { type: "text", text: "Sub-Item", align: "center", value: "subItem", width: 40 },
      { type: "text", text: "Non-Historical-Filter", align: "center", value: "nonFilter", width: 40 },
      { type: "text", text: "Permission", align: "left", value: "permitName", width: 200 },
      { type: "text", text: "Writable Button", align: "left", vlaeu: "", width: 200 },
      { type: "text", text: "Compoment", align: "left", value: "customCompoment",  width: 150 },
      { type: "text", text: "Direction", align: "left", value: "direction",  width: 150 },
      { type: "text", text: "update", align: "left", value: "update",  width: 150 }
    ],
    navItem: [],
    // loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    defaultRules: [v => !!v || "Required"],
    codeRules: [v => (!!v && v != "ALL" && v.length > 0) || "Required"],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length <= 80) || "Less then 80 characters"
    ],
    pathRules: [
      v =>
        /^[a-zA-Z0-9@/]*$/.test(v) ||
        "Not allowed space and special letters except @ /"
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    iconInfo: {},
    yes: () => { },
    wButton: [],
    writableBoxOpen: false,
    writableBoxReqItem: {},
    writableBoxComparedItem: {},
    writableStatus: "",
    iconPickerOpen: false
  }),
  watch: {
    modOpen(val) {
      if (
        !val &&
        (this.writableStatus == "UPDATE" || this.writableStatus == "DELETE")
      ) {
        this.systemService.getNavItem(
          this.setNavItem,
          this.codePropagated.parentCode,
          this.codePropagated.code
        )
      }
    },
    menuPermitSelected(val) {
      let menuPermits = []
      val.forEach(m => {
        menuPermits.push(m.code)
      })
      this.selectedItem.itemPermit = menuPermits.join(",")
    },
    codePropagated: {
      handler(val) {
        this.headersChange(val) // headers 변경
        // to retain or set page's default status in 'Add'
        this.selectedIndex = -3
        // sortable 작업 후 하위 메뉴 이동시 table 강제로 리로드
        this.navItem = [{ dummy: "dummy" }]
        // retrieve the list for the new condition
        this.systemService.getNavItem(this.setNavItem, val.parentCode, val.code)
      },
      deep: true
    }
  },
  created() {
    this.systemService = new SystemService()
    this.systemCodeService = new SystemCodeService()
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = res

      this.systemService.getCodeIndex("PERMISSION", res => {
        if (!res) this.menuPermitOptions = []
        else this.menuPermitOptions = res

        this.systemService.getCodeIndex("SVC_STATUS", res => {
          if (!res) this.statusOptions = []
          else
            res.forEach(item => {
              this.statusOptions.push({ text: item.name, value: item.code })
            })
          this.systemService.getNavItem(this.setNavItem, this.codePropagated.parentCode, this.codePropagated.code)
        })
      })
    })
    this.headersChange(this.codePropagated) // headers 변경
  },
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit("sysenv/codePropagate", value) }
    },
    menuUpdated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    }
  },
  methods: {
    setWButton(res) {
      if (!res) res = []
      this.wButton = res
    },
    openWButton(item) {
      this.writableBoxReqItem = Object.assign({}, item)
      this.writableBoxComparedItem = Object.assign({}, item)
      this.writableBoxOpen = true
    },
    writableBoxChanged(status) {
      // Menu Item 등록/수정화면에서 나갔을 때 Data Table을 re-render
      // UPDATE, DELETE 값의 경우만 re-render, CANCLE 에서는 X
      this.writableStatus = status

      setTimeout(() => {
        // Menu Item 등록/수정화면의 Writable Data re-render
        this.systemService.getWButton(this.setWButton, this.selectedItem.phaseCode, this.selectedItem.groupCode, this.selectedItem.itemCode)
      }, 200)
      this.writableBoxOpen = false
    },
    iconSelect(icon) {
      this.icon = icon
      this.selectedItem.navIcon = icon.iconCode
      this.selectedItem.navIconName = icon.fileName
      this.selectedItem.navIconPath = icon.filePath
    },
    removeIcon(e) {
      this.icon = {}
      this.selectedItem.navIcon = ""
      this.selectedItem.navIconPath = ""
      this.selectedItem.navIconName = ""
    },
    popIconPicker(opened) {
      if (!opened) return

      this.systemService.getIcons(__C.IMAGE.ICON_TYPE_SVG, res => {
        if (!res) res = []
        this.iconList = res
      })
    },
    phaseChanged(value) {
      // if (this.prevCodes.groupCode == "") this.selectedItem.groupCode = ""

      this.systemService.getNavGroup(value, res => {
        if (!res) this.groupOptions = []
        else this.groupOptions = res

        this.prevCodes.groupCode = ""
      })
    },
    setNavItem(res) {
      this.modOpen = false
      if (!res) res = []
      // console.log(`res: %s`, JSON.stringify(res))

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined to the data-date' item template
      // it doesn't need to add manually...
      // res.forEach(item => { item['no'] = 0 })
      this.navItem = res

      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event
      if (this.selectedIndex < -1) {
        ++this.selectedIndex

        // if this action comes from that add new menu item,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        // Attention, this is itemName, not the itemCode...
        this.setEdit(
          this.navItem.findIndex(x => x.itemName === this.selectedItem.itemName)
        )
      } else {
        // if this action comes from that delete existing menu item,
        // select a forward or backward item.
        if (
          this.navItem.findIndex(
            x => x.itemCode === this.selectedItem.itemCode
          ) < 0
        ) {
          if (this.navItem.length > 0) {
            if (this.navItem.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.navItem.length - 1
            }
            this.setEdit(this.selectedIndex)
          } else {
            // 삭제시 등록화면 호출 부분 제거
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex)
        }
      }
    },
    setUrl() {
      let phase = this.phaseOptions.find(p => p.phaseCode == this.selectedItem.phaseCode)
      let group = this.groupOptions.find(g => g.groupCode == this.selectedItem.groupCode)

      this.selectedItem.urlSelf = `/${this.selectedItem.itemName.toLowerCase().replace(/[\W]+/g, '_')}`
      this.selectedItem.url = (
        '/service' +
        `/${phase.phaseName.toLowerCase().replace(/[\W]+/g, '_')}` +
        `/${group.groupName.toLowerCase().replace(/[\W]+/g, '_')}` +
        this.selectedItem.urlSelf
      )
      this.selectedItem.type = 'Item'
    },
    onRowMoved(val) {
      const rowSelected = this.navItem.splice(val.oldIndex, 1)[0] // Get the selected row and remove it
      this.navItem.splice(val.newIndex, 0, rowSelected) // Move it to the new index
      this.snackbar = false
      this.systemService.setPriority(
        {
          newIdex: val.newIndex,
          oldIndex: val.oldIndex,
          targetName: "NAV_ITEM",
          conditionName1: this.codePropagated.parentCode,
          conditionName2: this.codePropagated.code
        },
        () => {
          this.snackbar = true
          this.menuUpdated = true
          this.systemService.getNavItem(
            (res) => { 
              this.navItem = []
              // Need some time-delay for draggable process
              setTimeout(() => { this.setNavItem(res) })
            }, 
            this.codePropagated.parentCode, 
            this.codePropagated.code
          )
        }
      )
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1
      })
    },
    dbTranster(props) {
      const _self = this
 
      this.yes = () => {
        _self.loading = true
        this.msgOpen = false
        this.systemService.transferItem(props.item, res => {
          _self.loading = false
          _self.menuUpdated = true
          this.updateMsg(res)
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Transfer Item."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Menu Item?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },
    updateMsg(val) {
      if (val.data == "Clear") {
        this.msgInfo.type = "INFO"
        this.msgInfo.title = "Success to Update."
        this.msgInfo.titleDescription = "Transfer Item Success."
        this.msgInfo.message = val.data
        this.msgInfo.button = [false, false, true]
        this.msgOpen = true        
      }else {
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Can Not Update"
        this.msgInfo.titleDescription = "Check the message."
        this.msgInfo.message = val.data
        this.msgInfo.button = [false, false, true]
        this.msgOpen = true
      }
    },
    add() {
      const _self = this

      this.yes = () => {
        this.msgOpen = false
        this.setUrl()
        this.systemService.putNavItem(this.selectedItem, res => {
          this.systemService.getNavItem(
            this.setNavItem,
            this.codePropagated.parentCode,
            this.codePropagated.code
          )
          _self.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save new Menu Item?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    edit() {
      const _self = this
      this.yes = () => {
        this.msgOpen = false
        
        this.setUrl()
        this.systemService.updNavItem(this.selectedItem, res => {
          this.systemService.getNavItem(
            this.setNavItem,
            this.codePropagated.parentCode,
            this.codePropagated.code
          )
          _self.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Save Changes."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to save this changes?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Save"
      this.msgOpen = true
    },
    del() {
      const _self = this

      this.yes = () => {
        this.msgOpen = false
        this.systemService.delNavItem(this.selectedItem.itemCode, res => {
          this.systemService.getNavItem(
            this.setNavItem,
            this.codePropagated.parentCode,
            this.codePropagated.code
          )
          _self.menuUpdated = true
        })
        this.yes = () => { }
      }

      // 삭제하기 전에 삭제하려는 Item 데이터와 연결된 Sub-Item 데이터가 있으면 삭제를 못 하도록 설정
      this.systemService.getNavRelated(
        "Sub-Item",
        this.selectedItem.itemCode,
        res => {
          if (res == 0) {
            this.msgInfo.type = "WARN"
            this.msgInfo.title = "Action Approval"
            this.msgInfo.titleDescription = "Important Notification"
            this.msgInfo.message = "Do you want to delete selected Menu Item?"
            this.msgInfo.button = [true, false, true]
            this.msgInfo.buttonText = ["Yes", "No", "Cancel"]
            this.msgInfo.buttonText[0] = "Delete"
            this.msgOpen = true
          } else {
            this.msgInfo.type = "WARN"
            this.msgInfo.title = "Can Not Delete"
            this.msgInfo.titleDescription = "Important Notification"
            this.msgInfo.message =
              "Related Menu Sub-Item have " +
              res +
              " of data. " +
              "Please delete relevant data first."
            this.msgInfo.button = [false, false, true]
            this.msgOpen = true
          }
        }
      )
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.navItem.filter(e => {
          return (
            (e.itemName || "").toLowerCase().indexOf((v || "").toLowerCase()) >
            -1
          )
        })
        this.loading = false
      }, 500)
    },
    propStatus(e, props) {
      if (e.target.textContent == 'Update') this.modOpen = false
      else {
      // Writable 팝업 관련 : 수정 클릭시 Menu Item과 연결된 Wriatable Button 정보들을 가져온다.
      this.wButton = []
      this.systemService.getWButton(
        this.setWButton,
        props.item.phaseCode,
        props.item.groupCode,
        props.item.itemCode
      )

      this.selectedItem = Object.assign({}, props.item)
      this.selectedIndex = this.navItem.indexOf(props.item)

      this.prevCodes.phaseCode = props.item.phaseCode
      this.prevCodes.groupCode = props.item.groupCode
    
      this.phaseChanged(this.selectedItem.phaseCode)

      this.icon = {}
      this.icon.fileName = this.selectedItem.navIconName
      this.icon.filePath = this.selectedItem.navIconPath
      this.icon.iconCode = this.selectedItem.iconCode

      this.menuPermitSelected = []
      this.selectedItem.itemPermit.split(",").forEach(code => {
        let group = this.menuPermitOptions.find(o => o.code == code)
        if (!!group) this.menuPermitSelected.push(group)
      })

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].itemCode === props.item.itemCode) {
          this.modOpen = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }
      this.selectedPrevItem = [...this.selected]
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true
      }
    },
    setAdd() {
      // at the very first time access or page reloading,
      // it comes twise from 'watcher' and 'mounted' event.
      // so it should be retained to be increased by them until being -1.
      this.icon = {}
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.modOpen = true
      this.$refs.formHandler.formReset()
      this.selectedItem.groupCode = this.codePropagated.name != 'All' ? this.codePropagated.name : {}
      setTimeout(() => {
        this.selectedItem = {
          phaseCode: this.codePropagated.parentCode,
          groupCode:
            this.codePropagated.code !== "ALL" ? this.codePropagated.code : {}
        }
        this.selected = []
        this.selectedPrevItem = []
        this.menuPermitSelected = []
        this.phaseChanged(this.selectedItem.phaseCode)
      })
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex
      this.selectedItem = Object.assign({}, this.navItem[this.selectedIndex])
      this.selected = [Object.assign({}, this.navItem[this.selectedIndex])]
      this.selectedPrevItem = [...this.selected]
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (
          (this.codePropagated.code === "ALL" && !!this.selectedItem.phaseCode) ||
          !!this.selectedItem.groupCode ||
          !!this.selectedItem.itemName ||
          !!this.selectedItem.itemStatus ||
          !!this.selectedItem.navIcon ||
          !!this.selectedItem.subItem ||
          !!this.selectedItem.toolBar ||
          !!this.selectedItem.url ||
          !!this.selectedItem.direction ||
          !!this.selectedItem.cnName ||
          !!this.selectedItem.pdfTime ||
          !!this.selectedItem.nonFilter ||
          !!this.selectedItem.customCompoment 
        ) return true

        return false

      } else {
        if (
          this.selectedPrevItem[0].phaseCode != this.selectedItem.phaseCode ||
          this.selectedPrevItem[0].groupCode != this.selectedItem.groupCode ||
          this.selectedPrevItem[0].itemName != this.selectedItem.itemName ||
          this.selectedPrevItem[0].itemPermit != this.selectedItem.itemPermit ||
          this.selectedPrevItem[0].itemStatus != this.selectedItem.itemStatus ||
          this.selectedPrevItem[0].navIcon != this.selectedItem.navIcon ||
          this.selectedPrevItem[0].subItem != this.selectedItem.subItem ||
          this.selectedPrevItem[0].toolBar != this.selectedItem.toolBar ||
          this.selectedPrevItem[0].url != this.selectedItem.url ||
          this.selectedPrevItem[0].direction != this.selectedItem.direction ||
          this.selectedPrevItem[0].cnName != this.selectedItem.cnName ||
          this.selectedPrevItem[0].pdfTime != this.selectedItem.pdfTime ||
          this.selectedPrevItem[0].nonFilter !=this.selectedItem.nonFilter ||
          this.selectedPrevItem[0].customCompoment != this.selectedItem.customCompoment
        ) return true

        return false
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {}
        this.$refs.formHandler.formReset()

        setTimeout(() => {
          this.selectedItem =
            this.codePropagated.code !== "ALL"
              ? { phaseCode: this.codePropagated.code }
              : {}
          this.phaseChanged(this.selectedItem.phaseCode)
        })
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0])
      }
    },
    cancel() {
      this.modOpen = false
    },
    isCodeValid(itemCode) {
      return !this.navItem.find(item => item.itemCode == itemCode)
    },
    formatWButton(reportName) {
      var renderWB = ""
      if (reportName != "" && !!reportName) {
        var commaIndex = reportName.indexOf(",")
        if (commaIndex > -1) {
          reportName.split(",").forEach(val => {
            renderWB += `<v-btn class="writable j_button" >` + val + `</v-btn>`
          })
        } else {
          renderWB +=
            `<v-btn class="writable j_button" >` + reportName + `</v-btn>`
        }
      }
      return renderWB
    },
    headersChange(val) {
      let groupIndex = this.headers.findIndex(h => h.value === "groupName")
      let target = `${__C.HOST_NAME_RESOURCE}`
      if (target == 'https://tortue.jinsolutions.com/resources' || target == 'https://jgsdemo.jinsolutions.com/resources') {
       let upIndex = this.headers.findIndex(h => h.text == 'update')
       if (upIndex > -1 ) this.headers.splice(upIndex, 1)
       this.UpdateVisible = false  
      }         
      if (val.code === "ALL") {
        // headers no 변경
        let noIndex = this.headers.findIndex(h => h.value === "no")
        if (noIndex > -1) this.headers[noIndex].value = "itemSn"
        // headers Group 추가
        if (groupIndex < 0)
          this.headers.unshift({
            type: "text",
            text: "Group Title",
            align: "left",
            value: "groupName",
            width: 170
          })
        // headers dag 제거
        let dragIndex = this.headers.findIndex(h => h.type === "drag")
        if (dragIndex > -1) this.headers.splice(dragIndex, 1)
      } else {
        // headers no 변경
        let snIndex = this.headers.findIndex(h => h.value === "itemSn")
        if (snIndex > -1) this.headers[snIndex].value = "no"
        // headers Group 제거
        if (groupIndex >= 0) this.headers.shift()
        // headers dag 추가
        let dragIndex = this.headers.findIndex(h => h.type === "drag")
        if (dragIndex == -1)
          this.headers.unshift({
            type: "drag",
            text: "",
            align: "right",
            value: "",
            width: 30
          })
      }
    }
  }
}
</script>